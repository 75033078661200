<!--
 * @Description: AKJERRT
 * @Date: 2022-07-27 09:46:51
 * @LastEditTime: 2022-08-15 14:51:16
 * @FilePath: \linkgap-front\src\views\to_delivery_order\components\ConfirmDrawer.vue
-->
<template>
  <div>
    <a-drawer title="再次确认" placement="right" width="60%" :closable="false" :visible="visible" @close="close">
      <div class="warpper">
        <div class="warpper-list">
          <a-divider v-show="isProList" orientation="left"> 商品额度 </a-divider>
          <a-table v-show="isProList" :data-source="res_data.ProList" :columns="columns" :pagination="false" bordered>
            <span slot="SYKY" slot-scope="scope, row">
              {{ row.remainingAmountBackUp | formatMoney }}
            </span>
            <span slot="BCSY" slot-scope="scope, row">
              <a-input-number
                id="inputNumber"
                :max="Number(row.canUseMaxLimit)"
                :min="0"
                v-model="row.thisTimeUsedAmount"
                @change="handlerAmount(row, 'pro')"
              />
            </span>
          </a-table>
          <a-divider v-show="isAllType" orientation="left"> 类型额度 </a-divider>
          <a-table v-show="isAllType" :data-source="res_data.AllType" :columns="columns" :pagination="false" bordered>
            <span slot="SYKY" slot-scope="scope, row">
              {{ row.remainingAmountBackUp | formatMoney }}
            </span>
            <span slot="BCSY" slot-scope="scope, row">
              <a-input-number
                id="inputNumber"
                :max="Number(row.canUseMaxLimit)"
                :min="0"
                v-model="row.thisTimeUsedAmount"
                @change="handlerAmount(row, 'type')"
              />
            </span>
          </a-table>
          <a-divider v-show="isAllPro" orientation="left"> 通用额度 </a-divider>
          <a-table v-show="isAllPro" :data-source="res_data.AllPro" :columns="columns" :pagination="false" bordered>
            <span slot="SYKY" slot-scope="scope, row">
              {{ row.remainingAmountBackUp | formatMoney }}
            </span>
            <span slot="BCSY" slot-scope="scope, row">
              <a-input-number
                id="inputNumber"
                :max="Number(row.canUseMaxLimit)"
                :min="0"
                v-model="row.thisTimeUsedAmount"
                @change="handlerAmount(row, 'all')"
              />
            </span>
          </a-table>
        </div>
        <br />
        <div class="warpper-footer">
          <div class="warpper-footer-item price-text">
            <div class="warpper-footer-div" v-show="isTest">
              <span class="warpper-footer-text"><span style="color: red">*</span>&emsp;最大可使用额度：</span>
              <span>￥</span><span>{{ data.canUseMaxLimit | formatMoney }}</span>
            </div>
            <div></div>
            <div class="warpper-footer-div">
              <span class="warpper-footer-text footer-text">总金额：</span>
              <span>￥</span>
              <span class="warpper-footer-pirce">{{ data.actualPrice | formatMoney }}</span>
            </div>
          </div>
          <p class="warpper-footer-item text-color">
            (<span v-show="isTest" class="warpper-footer-text">额度支付：</span> <span v-show="isTest">￥</span
            ><span v-show="isTest" style="margin-right: 16px">{{ data.actualPriceLimit | formatMoney }}</span
            ><span class="warpper-footer-text">余额支付：</span> <span>￥</span
            ><span>{{ (data.actualPrice - data.actualPriceLimit) | formatMoney }}</span
            >)
          </p>
          <p class="warpper-footer-item" style="margin-top: -15px">
            <span class="warpper-footer-text">商品数量：</span> <span>{{ data.proSum }}</span>
          </p>
          <a-divider />
          <a-row class="warpper-footer-btn">
            <a-col><a-button @click="close" :loading="loading"> 取消 </a-button></a-col>
            <a-col><a-button type="primary" @click="handlerCon" :loading="loading"> 确认 </a-button></a-col>
          </a-row>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      isProList: true,
      isAllType: true,
      isAllPro: true,
      isTest: true, // 底部文字
      columns: [
        {
          title: '额度名称',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '剩余可用',
          dataIndex: 'remainingAmountBackUp',
          key: 'remainingAmountBackUp',
          align: 'center',
          scopedSlots: {
            customRender: 'SYKY',
          },
        },
        {
          title: '本单可用',
          dataIndex: 'canUseMaxLimit',
          key: 'canUseMaxLimit',
          align: 'center',
          scopedSlots: {
            customRender: 'BDSYKY',
          },
        },
        {
          title: '本次使用',
          dataIndex: 'thisTimeUsedAmount',
          key: 'thisTimeUsedAmount',
          align: 'center',
          scopedSlots: {
            customRender: 'BCSY',
          },
        },
      ],
      // 额度类型数据
      res_data: {
        AllPro: [], // 所有商品
        AllType: [], // 所有类型
        ProList: [], // 指定商品
      },
      data: {
        limitInfos: [],
        limitMap: [],
        orderShipDetails: [],
        actualPrice: 0, // 总额
        actualPriceBalance: 0, // 余额
        actualPriceLimit: 0, // 额度
        canUseMaxLimit: 0, // 最大可使用额度
        proSum: '',
      },
      initActualPriceLimit: '',
      all: 0,
      type: 0,
      pro: 0,
    }
  },
  methods: {
    handlerAmount(row) {
      if (row.thisTimeUsedAmount > row.remainingAmountBackUp) {
        row.thisTimeUsedAmount = row.remainingAmountBackUp
      }
      if (row.thisTimeUsedAmount <= 0) {
        row.thisTimeUsedAmount = 0
      }
      let all = 0
      let type = 0
      let pro = 0
      if (this.res_data.AllPro && this.res_data.AllPro.length > 0) {
        this.res_data.AllPro.forEach((element, idnex) => {
          all += element.thisTimeUsedAmount
        })
      }
      this.all = all
      if (this.res_data.AllType && this.res_data.AllType.length > 0) {
        this.res_data.AllType.forEach((element, idnex) => {
          type += element.thisTimeUsedAmount
        })
      }
      this.type = type
      if (this.res_data.ProList && this.res_data.ProList.length > 0) {
        this.res_data.ProList.forEach((element, idnex) => {
          pro += element.thisTimeUsedAmount
        })
      }
      this.pro = pro
      this.data.actualPriceLimit = this.pro + this.type + this.all
      console.log(this.data.actualPriceLimit, 'this.data.actualPriceLimit')
      console.log(all, 'all')
      console.log(type, 'type')
      console.log(pro, 'pro')
    },
    showDrawer(res, proSum) {
      if (proSum) {
        this.data.proSum = proSum
      }
      // 通用额度
      if (res.body.limitMap && res.body.limitMap[1]) {
        this.res_data.AllPro = res.body.limitMap[1]
      } else {
        console.log('通用额度不存在')
        // this.isAllPro = false
      }
      // else if (res.body.limitMap[1].length < 0) {
      //   this.isAllPro = false
      // }
      // 全部类型
      if (res.body.limitMap && res.body.limitMap[2]) {
        this.res_data.AllType = res.body.limitMap[2]
      } else {
        console.log('全部类型不存在')
        // this.isAllType = false
      }
      // 指定商品
      if (res.body.limitMap && res.body.limitMap[3]) {
        this.res_data.ProList = res.body.limitMap[3]
      } else {
        console.log('指定商品不存在')
        // this.isProList = false
      }
      // footer 价格
      this.data.actualPrice = res.body.actualPrice
      this.data.actualPriceBalance = res.body.actualPriceBalance
      // 额度
      const initActualPriceLimit = Object.assign(res.body.actualPriceLimit)
      this.initActualPriceLimit = initActualPriceLimit
      this.data.actualPriceLimit = res.body.actualPriceLimit
      this.data.canUseMaxLimit = res.body.canUseMaxLimit
      if (this.data.canUseMaxLimit <= 0) {
        this.isTest = false
      }
      console.log(JSON.parse(JSON.stringify(res.body)), 'res.body')
      console.log(JSON.parse(JSON.stringify(this.res_data)), 'res_data')
      this.visible = true
    },
    //
    close() {
      this.visible = false
      this.loading = false
      this.res_data = {
        AllPro: [], // 所有商品
        AllType: [], // 所有类型
        ProList: [], // 指定商品
      };
      console.log("清除缓存",this.res_data);
    },
    closeLoading() {
      this.loading = false
    },
    // 关闭抽屉
    onClose() {
      this.visible = false
      this.loading = false
      this.$emit('callbackComponent', {
        function: 'onClose',
      })
    },
    // emit 回调
    handlerCon() {
      this.loading = true
      console.log(this.data.actualPriceLimit, '额度')
      console.log(this.data.actualPrice, '总金额')
      // 校验信用额度是否大于总金额
      if (this.data.actualPriceLimit > this.data.actualPrice) {
        return this.$notification.warning({ message: '金额错误 信用额度大于总金额' })
      } else {
        console.log(this.data.actualPriceLimit)
        console.log(this.data.actualPrice)
      }
      this.data.actualPriceBalance = this.data.actualPrice - this.data.actualPriceLimit
      console.log(JSON.parse(JSON.stringify(this.data)), 'this.data')
      console.log(JSON.parse(JSON.stringify(this.res_data)), 'this.res_data')
      this.$emit('callbackComponent', {
        function: 'sendOrderShipIfo',
        data: { res_data: this.res_data, price: this.data },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.warpper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 88vh;
  .warpper-list {
    display: flex;
    flex-grow: 10;
    width: 100%;
    flex-direction: column;
    overflow: auto;
  }
  .warpper-footer {
    flex-direction: column;
    align-items: flex-end;
    display: flex;
    flex-grow: 0.1;
    justify-content: flex-end;
    .warpper-footer-div {
      display: flex;
      :nth-child(2) {
        display: flex;
        align-items: center;
      }
    }
    .warpper-footer-item {
      width: 24rem;
      display: flex;
      justify-content: flex-end;
    }
    .text-color {
      color: rgb(174, 174, 174);
      font-weight: 500;
    }
    .warpper-footer-text {
      display: block;
      text-align: right;
    }
    .warpper-footer-btn {
      display: flex;
      flex-direction: row;
    }
    .warpper-footer-pirce {
      font-size: 1.5rem;
      font-weight: 900;
      color: red;
    }
    .footer-text {
      font-size: 1.3rem;
      font-weight: 900;
      display: flex;
      align-items: flex-end;
    }
    .price-text {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}
</style>